import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import store from "@/store";

export default class TriviaDataService {
    static async getTriviaQuestions(msisdn, gameChallengeId) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions`, {
            msisdn: msisdn,
            amountOfQuestions: 10,
            difficulty: 1,
            gameChallengeId: gameChallengeId,
            productId: store.state.brandConfig.productId,
        })
    }

    static async getTriviaQuestionsV2(encryptedMsisdn, gameChallengeId) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            amountOfQuestions: 10,
            difficulty: 1,
            gameChallengeId: gameChallengeId,
            productId: store.state.brandConfig.productId,
        })
    }

    static async submitUserAnswer(answer, gameId) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers`, {
            answer: answer,
            productId: store.state.brandConfig.productId,
            gameId: gameId
        })
    }

    static async submitUserAnswerV2(answer, gameId) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers/v2`, {
            answer: answer,
            productId: store.state.brandConfig.productId,
            gameId: gameId,
        })
    }

    static async getRoundLeaderboard(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard`, {
            msisdn: msisdn,
            productId: store.state.brandConfig.productId,
            numberRows: 10,
        })
    }

    static async getRoundLeaderboardV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            productId: store.state.brandConfig.productId,
            numberRows: 10,
        })
    }

    static async upsertUsername(msisdn, username) {
        return await axios.post(`${environmentConfig.triviaService}/upsert-username`, {
                msisdn,
                username
            },
        )
    }

    static async upsertUsernameV2(encryptedMsisdn, username) {
        return await axios.post(`${environmentConfig.triviaService}/upsert-username/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                username
            },
        )
    }

    static async getUserStatistics(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-stats`, {
                msisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async getUserStatisticsV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-stats/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async getUserGameHistory(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-game-history`, {
                msisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async getUserGameHistoryV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-game-history/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async getUserGameFunds(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-game-funds`, {
                msisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async getUserGameFundsV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-game-funds/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId: store.state.brandConfig.productId
            },
        )
    }

    static async claimUserGameFunds(gameFundsId) {
        return await axios.post(`${environmentConfig.triviaService}/claim-game-funds`, {
                gameFundsId
            },
        )
    }
}
