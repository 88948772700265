import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import {ServiceIdConfig} from "@/config/serviceIdConfig";

export default class MultiplayerTriviaService {
    static async sendChallengeRequest(msisdn, username, friendMsisdn, initiatingChallenge, acceptingChallenge) {
        const randomSearch = initiatingChallenge || acceptingChallenge ? 0 : 1;
        return await axios.post(`${environmentConfig.triviaService}/challenge-opponent`, {
                msisdn,
                username,
                friendMsisdn,
                initiatingChallenge,
                acceptingChallenge,
                randomSearch,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async cancelChallengeRequest(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/cancel-challenges`, {
                msisdn,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async sendFriendRequest(msisdn, username, friendMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/send-friend-request`, {
                msisdn,
                username,
                friendMsisdn,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async respondToFriendRequest(msisdn, friendMsisdn, response) {
        return await axios.post(`${environmentConfig.triviaService}/respond-to-friend-request`, {
                msisdn,
                friendMsisdn,
                response,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async getFriendRequests(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-friend-requests`, {
                msisdn,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async getFriendList(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-friends-list`, {
                msisdn,
                productId: ServiceIdConfig.productId
            }
        )
    }

    static async getPlayerGameChallenges(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-player-game-challenges`, {
                msisdn,
                productId: ServiceIdConfig.productId
            }
        )
    }
}
