import {createStore} from 'vuex'
import {ServiceIdConfig} from "@/config/serviceIdConfig";

export default createStore({
    state: {
        isLoading: false,
        user: undefined,
        serviceIdConfig: ServiceIdConfig,
        msisdn: undefined,
        encryptedMsisdn: undefined,
        token: undefined,
        brandConfig: undefined,
        burgerMenuIconVisible: true,
        campaignId: null,
        googleClickId: undefined,
        showHowToPlayPopup: false,
        activeGameQuestions: [],
        serviceId: undefined,
        showPendingChallengeRequests: false,
        pendingChallengeRequests: [],
        secondsToGameStart: undefined,
        secondsFromGameStart: undefined,
        opponentUsername: undefined,
        gameChallengeId: undefined,
        currentUserGameResult: undefined,
        opponentUserGameResult: undefined,
        challengePlayerTimeoutId: undefined,
        currentGameFinished: false,
        showInsufficientFunds: false,
        sessionId: undefined,
        evinaToken: undefined,
        rewardPopUp: false,
        subscriptionPackage : undefined,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setUser(state, user) {
            state.user = user;
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setSessionId(state, sessionId) {
            state.sessionId = sessionId
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn;
        },
        setEncryptedMsisdn(state, encryptedMsisdn) {
            state.encryptedMsisdn = encryptedMsisdn
        },
        setToken(state, token) {
            state.token = token
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig;
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
        setBurgerMenuIconVisible(state, burgerMenuIconVisible) {
            state.burgerMenuIconVisible = burgerMenuIconVisible;
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setShowHowToPlayPopup(state, showHowToPlayPopup) {
            state.showHowToPlayPopup = showHowToPlayPopup
        },
        setShowPlayerProfile(state, showPlayerProfile) {
            state.showPlayerProfile = showPlayerProfile;
        },
        setActiveGameQuestions(state, activeGameQuestions) {
            state.activeGameQuestions = activeGameQuestions;
        },
        setShowPendingChallengeRequests(state, showPendingChallengeRequests) {
            state.showPendingChallengeRequests = showPendingChallengeRequests;
        },
        setPendingChallengeRequests(state, pendingChallengeRequests) {
            state.pendingChallengeRequests = pendingChallengeRequests;
        },
        setSecondsToGameStart(state, secondsToGameStart) {
            state.secondsToGameStart = secondsToGameStart;
        },
        setSecondsFromGameStart(state, secondsFromGameStart) {
            state.secondsFromGameStart = secondsFromGameStart;
        },
        setOpponentUsername(state, opponentUsername) {
            state.opponentUsername = opponentUsername;
        },
        setGameChallengeId(state, gameChallengeId) {
            state.gameChallengeId = gameChallengeId;
        },
        setCurrentUserGameResult(state, currentUserGameResult) {
            state.currentUserGameResult = currentUserGameResult;
        },
        setOpponentUserGameResult(state, opponentUserGameResult) {
            state.opponentUserGameResult = opponentUserGameResult;
        },
        setChallengePlayerTimeoutId(state, challengePlayerTimeoutId) {
            state.challengePlayerTimeoutId = challengePlayerTimeoutId;
        },
        setCurrentGameFinished(state, currentGameFinished) {
            state.currentGameFinished = currentGameFinished;
        },
        setShowInsufficientFunds(state, showInsufficientFunds) {
            state.showInsufficientFunds = showInsufficientFunds;
        },
        setRewardPopup(state, rewardPopUp) {
            state.rewardPopUp = rewardPopUp
        },
        setSubscriptionPackage(state, subscriptionPackage) {
            state.subscriptionPackage = subscriptionPackage
        },
    },
    actions: {},
    modules: {}
})
