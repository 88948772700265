import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    prizeWonLeaderboard: `${process.env.VUE_APP_PRIZE_WON_LEADERBOARD}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    headerEnrichmentGame: `${process.env.VUE_APP_HEADER_ENRICHMENT_GAME}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
}

export const stagingConfig = {
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    prizeWonLeaderboard: `${process.env.VUE_APP_PRIZE_WON_LEADERBOARD}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    headerEnrichmentGame: `${process.env.VUE_APP_HEADER_ENRICHMENT_GAME}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
