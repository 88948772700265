import store from "@/store";
import DcbService from "../services/dcbService";
import setUserSessionData from "@/functions/setUserSessionData";
import TriviaDataService from "@/services/triviaDataService";
import {ServiceIdConfig} from "@/config/serviceIdConfig";
import router from "@/router";

export async function handleActionClick(user, action, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const evinaToken = urlParams.get('evinaToken') ?? localStorage.getItem('evinaToken') ?? store.state.evinaToken;
    if (action === 'accept') await handleAcceptButton(evinaToken, user, next);
    else if (action === 'accept-tier2-v1') await handleAcceptButtonTier2Version1(user, next);
    else await handleExitButton(evinaToken, user);
}


async function handleExitButton(evinaToken, user) {
    store.commit('setIsLoading', true);
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBADP"
    else contentEnabled = "UDP"
    await DcbService.subscribeEncryptedUser(store.state.encryptedMsisdn, store.state.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    store.commit('setIsLoading', false);
    window.location = 'https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill';
}

async function handleAcceptButton(evinaToken, user, next) {
    store.commit('setIsLoading', true);
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBAAP";
    else contentEnabled = "UAP"
    await DcbService.trackUserUpgradeV2(user.token, store.state.serviceIdConfig.productId, store.state.serviceIdConfig.free, store.state.serviceIdConfig.vip);
    const subUserResponse = await DcbService.subscribeEncryptedUser(user.encryptedMsisdn, user.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    await handleRouting(subUserResponse.data, next);
}

async function handleRouting(userSubData, next) {
    if (userSubData.result === true) {
        setUserSessionData(userSubData.msisdn, userSubData.serviceId);
        store.commit('setServiceId', userSubData.serviceId);
        const user = await DcbService.getUserViaEncryptedMsisdn(userSubData.encryptedMsisdn, userSubData.serviceId, this.user.sessionId);
        const getFundsResponse = await TriviaDataService.getUserGameFundsV2(this.user.msisdn);
        store.state.user.data.coinsConfig = getFundsResponse.data;
        return next({name: 'landing'});
    } else handleSubErrorResponse(userSubData, next);
}

function handleSubErrorResponse(userSubData, next) {
    if (userSubData.status === 'Content Blocked') next({name: 'content-block'});
    else if (userSubData.status === 'Insufficient Funds') next({name: 'insufficient-funds'});
    else next({name: 'error'});
}

async function handleAcceptButtonTier2Version1(user, next) {
    store.commit('setIsLoading', true);
    await DcbService.doiTwo(user.msisdn);
    const subscribeUserResponse = await DcbService.subscribeUser(user.msisdn, store.state.campaignId, store.state.brandConfig.headToHeadServiceId);
    await handleUserSubscribeResponse(user, subscribeUserResponse.data, next);
}

async function handleUserSubscribeResponse(user, subscribeUserResponse, next) {
    if (subscribeUserResponse.result) {
        const getUserResponse = await DcbService.getUserViaProductId(user.msisdn, store.state.brandConfig.productId);
        const getFundsResponse = await TriviaDataService.getUserGameFunds(user.msisdn);
        getUserResponse.data.coinsConfig = getFundsResponse.data;
        setUserSessionData(user.msisdn, ServiceIdConfig.vip);
        await setUserData(getUserResponse.data);
        router.replace({'query': null});
        return next({path: '/landing'});
    } else return next({path: `/error`});
}

async function setUserData(userDataResponse) {
    await DcbService.trackLogin(userDataResponse.msisdn);
    store.commit('setUser', userDataResponse);
    store.commit('setMsisdn', userDataResponse.msisdn);
    store.commit('setServiceId', userDataResponse.serviceId);
    localStorage.setItem('token', userDataResponse.token);
}
