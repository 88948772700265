export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#E7562A" },
        { className: "secondary-bg-colour", colour: "#DE1F5F" },
        { className: "tertiary-bg-colour", colour: "#e34d7c" },
    ],
    text: [
        { className: "primary-text", colour: "#FFFFFF" },
        { className: "secondary-text", colour: "#DE1F5F" },
        { className: "tertiary-text", colour: "#E7562A" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#EF151C" },
        { className: "secondary-border-colour", colour: "#EF911B" },
    ]
};
