import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

export default class UtilsService {
    static async checkUsernameProfanity(username) {
        return await axios.post(`${environmentConfig.utilsService}/utils/check-profanity`, {
                textString: username,
            },
        )
    }

    static async getMsisdnFromEncryptedMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.utilsService}/userDataDecryption/decryptenrichedmsisdn?encryptedMsisdn=${encryptedMsisdn}`);
    }
}
