import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://quizhead2head.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://play.quizhead2head.co.za', getBrandConfig('vc-encrypt-za-en'));
brandConfigMap.set('https://staging.quizhead2head.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.0.110:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.88.231:8080', getBrandConfig('vc-za-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        productId: brandConfigObject.productId,
        headToHeadServiceId: brandConfigObject.headToHeadServiceId,
        quizIncAllAccessServiceId: brandConfigObject.quizIncAllAccessServiceId,
        baseRoute: brandConfigObject.baseRoute,
        encryptedRoutes: brandConfigObject.encryptedRoutes,
    }
}
