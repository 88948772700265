export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    VodacomEvinaSouthAfricaEnglish: 'vc-encrypt-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    gameTitle: 'Quiz Inc Head 2 Head',
    productId: 21,
    headToHeadServiceId: 'vc-fanclash-quizinc-04',
    quizIncAllAccessServiceId: 'vc-fanclash-quizinc-02',
    baseRoute: '/welcome',
    encryptedRoutes: false
});

brandConfigObjectMap.set(Brands.VodacomEvinaSouthAfricaEnglish, {
    gameTitle: 'Quiz Inc Head 2 Head',
    productId: 21,
    headToHeadServiceId: 'vc-fanclash-quizinc-04',
    quizIncAllAccessServiceId: 'vc-fanclash-quizinc-02',
    baseRoute: '/doi-two',
    encryptedRoutes: true
});
